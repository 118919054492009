/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/dylanNew/live2d@master/webgl/Live2D/lib/live2d.min.js
 * - /npm/pixi.js@6.5.2/dist/browser/pixi.min.js
 * - /npm/pixi-live2d-display@0.4.0/dist/index.min.js
 * - /gh/Weidows-projects/Live2dLoader@master/src/Live2dLoader.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
